<template>
    <div>
        <b-breadcrumb style="background-color: #ffffff" :items="items"></b-breadcrumb>
        <h1 class="text-left">Overview</h1>
        <div v-if="this.$store.getters.accessToken === null || this.$store.getters.accessToken === ''"
             style="max-width: 400px; margin-left: auto; margin-right: auto">
            <b-row align-h="center" class="justify-content-center text-center">
                <b-col class="text-left" sm="12">
                    <label :for="BoxType">{{ $t('overview.boxType') }}:</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input style="max-width: 400px" id="BoxType" v-model="boxType" disabled type="text">Q20
                    </b-form-input>
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col class="text-left" sm="12">
                    <label :for="MacAddress">MAC address:</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input id="MacAddress" disabled type="text" v-model="macAddress">AA:BB:CC:DD:EE:FF
                    </b-form-input>
                </b-col>
            </b-row>
            <b-row align-h="center" style="margin-bottom: 5px">
                <b-col class="text-left" sm="12">
                    <label :for="'country'">{{ $t('register.country') }}:</label>
                </b-col>
                <b-col sm="12">
                    <b-form-select :id="'country'" v-model="country" :options="countries"></b-form-select>
                </b-col>
            </b-row>
            <!--b-row class="justify-content-center">
              <b-col class="text-left" sm="12">
                <label :for="Amount">{{ $t('overview.amount') }}:</label>
              </b-col>
              <b-col sm="12">
                <b-form-input id="Amount" v-model="amount" disabled type="text">10€</b-form-input>
              </b-col>
            </b-row-->
            <b-row class="justify-content-center">
                <b-col class="text-left" sm="12">
                    <label :for="TotalAmount">{{ $t('overview.totalAmount') }}:</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input id="TotalAmount" v-model="totalAmount" disabled type="text">9,90€</b-form-input>
                </b-col>
                <b-col class="text-left" sm="12">
                    <p>inc. 22% VAT</p>
                </b-col>
                <b-col sm="12" class="text-left">
                    <b-form-checkbox
                            id="checkbox-1"
                            v-model="status"
                            name="checkbox-1"
                            value="accepted"
                            unchecked-value="not_accepted"
                            :state="state"
                    >
                        <b-link style="color: #2c3e50" href="#/termsAndConditions"> {{
                            $t('overview.withdrawlRight')
                            }}
                        </b-link>
                    </b-form-checkbox>
                    <b-form-invalid-feedback :state="state">You need to accept that you lose your withdrawal right
                    </b-form-invalid-feedback>
                </b-col>
            </b-row>
        </div>
        <div v-else>
            <b-row align-h="center">
                <b-col>
                    <div style="max-width: 400px; margin-left: auto; margin-right: auto">
                        <h3 class="text-center mb-5" style="background-color: #0000FF; padding: 5px; border-style: solid; color: white;
            border-width: medium; border-color: black">{{ $t('register.billingAddress') }}</h3>
                        <b-row align-h="center" class="justify-content-center text-center">
                            <b-col class="text-left" sm="12">
                                <label :for="firstName">{{ $t('register.firstName') }}:</label>
                            </b-col>
                            <b-col sm="12">
                                <b-form-input style="max-width: 400px" id="firstName" v-model="lastName" disabled
                                              type="text"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="justify-content-center">
                            <b-col class="text-left" sm="12">
                                <label :for="lastName">{{ $t('register.lastName') }}:</label>
                            </b-col>
                            <b-col sm="12">
                                <b-form-input id="lastName" disabled type="text" v-model="firstName"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="justify-content-center">
                            <b-col class="text-left" sm="12">
                                <label :for="street">{{ $t('register.street') }}</label>
                            </b-col>
                            <b-col sm="12">
                                <b-form-input id="street" v-model="user.street" disabled type="text"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="justify-content-center">
                            <b-col class="text-left" sm="12">
                                <label :for="Postcode">{{ $t('register.postcode') }}, </label>
                                <label :for="City">{{ $t('register.city') }}:</label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input id="Postcode" v-model="user.zip" disabled type="text"></b-form-input>
                            </b-col>
                            <b-col sm="8">
                                <b-form-input id="City" v-model="user.city" disabled type="text"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="justify-content-center">
                            <b-col class="text-left" sm="12">
                                <label :for="Country">{{ $t('register.country') }}</label>
                            </b-col>
                            <b-col sm="12">
                                <b-form-select disabled id="Country" v-model="user.country"
                                               :options="countries"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row class="justify-content-center">
                            <b-col class="text-left" sm="12">
                                <label :for="Email">E-mail:</label>
                            </b-col>
                            <b-col sm="12">
                                <b-form-input id="Email" disabled type="text" v-model="user.email"></b-form-input>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
                <b-col>
                    <div style="max-width: 400px; margin-left: auto; margin-right: auto">
                        <b-row align-h="center" class="justify-content-center text-center">
                            <b-col class="text-left" sm="12">
                                <label :for="BoxType">{{ $t('overview.boxType') }}:</label>
                            </b-col>
                            <b-col sm="12">
                                <b-form-input style="max-width: 400px" id="BoxType" v-model="boxType" disabled
                                              type="text">Q20
                                </b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="justify-content-center">
                            <b-col class="text-left" sm="12">
                                <label :for="MacAddress">MAC address:</label>
                            </b-col>
                            <b-col sm="12">
                                <b-form-input id="MacAddress" disabled type="text" v-model="macAddress">
                                    AA:BB:CC:DD:EE:FF
                                </b-form-input>
                            </b-col>
                        </b-row>
                        <!--b-row class="justify-content-center">
                          <b-col class="text-left" sm="12">
                            <label :for="Amount">{{ $t('overview.amount') }}:</label>
                          </b-col>
                          <b-col sm="12">
                            <b-form-input id="Amount" v-model="amount" disabled type="text">10€</b-form-input>
                          </b-col>
                        </b-row-->
                        <b-row class="justify-content-center">
                            <b-col class="text-left" sm="12">
                                <label :for="TotalAmount">{{ $t('overview.totalAmount') }}:</label>
                            </b-col>
                            <b-col sm="12">
                                <b-form-input id="TotalAmount" v-model="totalAmount" disabled type="text">9,90
                                </b-form-input>
                            </b-col>
                            <b-col class="text-left" sm="12">
                                <p>inc. 22% VAT</p>
                            </b-col>
                            <b-col sm="12" class="text-left">
                                <b-form-checkbox
                                        id="checkbox-1"
                                        v-model="status"
                                        name="checkbox-1"
                                        value="accepted"
                                        unchecked-value="not_accepted"
                                        :state="state"
                                >
                                    <b-link style="color: #2c3e50" href="#/termsAndConditions"> {{
                                        $t('overview.withdrawlRight')
                                        }}
                                    </b-link>
                                </b-form-checkbox>
                                <b-form-invalid-feedback :state="state">You need to accept that you lose your withdrawal
                                    right
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div>
            <b-row class="my-5" align-h="between">
                <b-col class="justify-content-start align-items-start text-left">
                    <router-link v-if="this.$store.getters.accessToken !== null" class="btn sansSerif mr-auto" style="background-color: #000000; border-radius: 0!important;
         color: white"
                                 :to="{ name: this.$route.query.boxName, query: {id: this.$route.query.id, boxName: this.$route.query.boxName, mac: this.$route.query.mac}}">
                        {{ $t('back') }}
                    </router-link>
                    <router-link v-else class="btn sansSerif mr-auto" style="background-color: #000000; border-radius: 0!important;
         color: white"
                                 :to="{ name: 'OrderOptions', query: {id: this.$route.query.id, boxName: this.$route.query.boxName, mac: this.$route.query.mac}}">
                        {{ $t('back') }}
                    </router-link>
                </b-col>
                <b-col class="justify-content-end align-items-end text-end">
                    <div style="max-width: 100px; height: 100%; margin-left: auto; margin-right: 50px">
                        <div id="paypal-button"/>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TransactionDataService from "../services/TransactionDataService";
import {mapState} from "vuex";

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
export default {
    name: "Overview",
    data() {
        return {
            boxType: this.$route.query.boxName,
            boxTypeId: this.$store.getters.boxType,
            amount: '9,90',
            totalAmount: '9,90',
            status: 'not_accepted',
            submitted: '',
            macAddress: this.$store.getters.macAddress,
            transaction: {
                id: null,
                userId: null,
                boxTypeId: this.$store.getters.boxType,
                macAddress: '',
                pvrNumber: '',
            },
            country: "IT",
        }
    },
    computed: {
        countries() {
            const list = countries.getNames('en', {select: 'official'})
            console.log(list);
            return Object.keys(list).map((key) => ({value: key, text: list[key]}))
        },
        state() {
            if (this.submitted) {
                return false;
            } else {
                return null;
            }
        },
        firstName() {
            let user = this.$store.getters.user;
            if (user.name != "") {
                let nameArray = user.name.split(" ");
                return nameArray[1];
            } else return " ";
        },
        lastName() {
            let user = this.$store.getters.user;
            if (user.name != "") {
                let nameArray = user.name.split(" ");
                return nameArray[0];
            } else return " ";
        },
        ...mapState(["user"]),
        items() {
            let token = this.$store.getters.accessToken;
            let items = [
                {
                    text: 'Home',
                    href: '#/home'
                },
                {
                    text: this.$route.query.boxName,
                    href: "#/" + this.$route.query.boxName + '?id=' + this.$route.query.id + '&boxName=' + this.$route.query.boxName,
                },
                {
                    text: this.$i18n.messages[this.$i18n.locale].orderOptions.orderOptions,
                    href: "#/orderOptions?id=" + this.$route.query.id + '&boxName=' + this.$route.query.boxName + '&mac=' + this.$route.query.mac
                },
                {
                    text: this.$i18n.messages[this.$i18n.locale].overview.overview,
                    active: true
                },
            ]
            if (token) {
                items = [
                    {
                        text: 'Home',
                        href: '#/home'
                    },
                    {
                        text: this.$route.query.boxName,
                        href: "#/" + this.$route.query.boxName + '?id=' + this.$route.query.id + '&boxName=' + this.$route.query.boxName,
                    },
                    {
                        text: this.$i18n.messages[this.$i18n.locale].overview.overview,
                        active: true
                    },
                ]
            }
            return items;
        }
    },
    methods: {
        createPaypalButton(paypal) {
            let FUNDING_SOURCES = [
                // eslint-disable-next-line no-undef
                paypal.FUNDING.PAYPAL,
            ];
            let a = this;
            // eslint-disable-next-line no-undef
            console.log(paypal);
            FUNDING_SOURCES.forEach(function (fundingSource) {
                // eslint-disable-next-line no-undef
                let button = paypal.Buttons({
                    fundingSource: fundingSource,
                    // Set up the transaction
                    createOrder(data, actions) {
                        console.log(a);
                        if (a.status === 'accepted' && (a.boxTypeId !== '' && a.boxTypeId !== null && typeof a.boxTypeId !== "undefined") &&
                            (a.macAddress !== '' && a.macAddress !== null && typeof a.macAddress !== "undefined")) {
                            /*a.submitted = '';
                            return actions.order.create({
                              purchase_units: [{
                                amount: {
                                  value: '9.90',
                                  currency_code: "EUR",
                                },
                              }],
                            });*/
                            console.log(data);
                            console.log(actions);
                            return TransactionDataService.createOrder()
                                .then((response) => response.data)
                                .then((order) => order.id);
                        } else {
                            console.log("IM ELSE");
                            a.submitted = true;
                            throw 'ERROR';
                        }
                    },

                    // Finalize the transaction
                    onApprove(data, actions) {
                        console.log(actions);
                        return TransactionDataService.captureOrder(data, a.getTrans())
                            .then((response) => response.data)
                            .then((orderData) => {
                                // Successful capture! For dev/demo purposes:
                                console.log("THIS IS THE ORDER DATA");
                                console.log(orderData);
                                a.saveTrans(orderData.transaction, orderData.fileName);
                                // When ready to go live, remove the alert and show a success message within this page. For example:
                                // var element = document.getElementById('paypal-button-container');
                                // element.innerHTML = '<h3>Thank you for your payment!</h3>';
                                // Or go to another URL:  actions.redirect('thank_you.html');
                            });
                        /*return actions.order.capture().then(details => {
                          // Show a success message to the buyer
                          //alert(`Transaction completed by ${details.payer.name.given_name}`);
                          console.log(details);
                          //a.saveTransaction();
                        });*/
                    },
                    OnError() {
                        return (err) => {
                            console.error(err);
                            alert("ERROR");
                        }
                    }
                });
                if (button.isEligible()) {
                    // Render the standalone button for that funding source
                    button.render('#paypal-button');
                }
            });
        },

        getTrans() {
            let country;
            if (this.user.id === 1) {
                country = this.country;
            } else {
                country = this.user.country;
            }
            return {
                userId: this.$store.getters.user.id,
                boxTypeId: this.boxTypeId,
                macAddress: this.macAddress,
                country: country,
                lang: this.$i18n.locale,
            };
        },

        saveTrans(data, filename) {
            this.transaction.id = data.id;
            this.$store.commit('SET_PVR_NUMBER', {pvrNumber: data.PvrNumber});
            this.transaction.pvrNumber = data.PvrNumber;
            console.log(data);
            this.$store.commit('SET_TRANSACTION_ID', {transactionId: data.id});
            this.$store.commit("SET_PURCHASED", {purchased: true});
            this.$store.commit("SET_FILENAME", {filename: filename});
            this.$router.push({
                name: 'pvrTicket',
                query: {id: this.$route.query.id, boxName: this.$route.query.boxName, mac: this.macAddress}
            });
        },

        saveTransaction() {
            console.log('user country');
            console.log(this.user.country);
            let country;
            if (this.user.id === 1) {
                country = this.country;
            } else {
                country = this.user.country;
            }
            let data = {
                userId: this.$store.getters.user.id,
                boxTypeId: this.boxTypeId,
                macAddress: this.macAddress,
                country: country,
            };
            console.log(this.macAddress);
            console.log(data);
            TransactionDataService.create(data)
                .then(response => {
                    this.transaction.id = response.data.id;
                    this.$store.commit('SET_PVR_NUMBER', {pvrNumber: response.data.PvrNumber});
                    this.transaction.pvrNumber = response.data.PvrNumber;
                    console.log(response.data);
                    this.$store.commit('SET_TRANSACTION_ID', {transactionId: response.data.id});
                    this.$store.commit("SET_PURCHASED", {purchased: true});
                    this.$router.push({
                        name: 'pvrTicket',
                        query: {id: this.$route.query.id, boxName: this.$route.query.boxName, mac: this.macAddress}
                    });
                })
                .catch(e => {
                    console.log(e);
                });
        },
    },
    mounted() {
        console.log("MOUNT MOUNT MOUNT!");
        console.log("MOUNT MOUNT MOUNT!");
        console.log(this.$store.getters.accessToken);

        if (this.$store.getters.macAddress) {
            this.macAddress = this.$store.getters.macAddress;
        } else {
            this.macAddress = this.$route.query.mac;
        }

        if (this.$store.getters.boxType) {
            this.boxTypeId = this.$store.getters.boxType;
        } else {
            this.boxTypeId = this.$route.query.id;
        }

        function loadScript(url, callback) {
            const el = document.querySelector(`script[src="${url}"]`);
            if (!el) {
                const s = document.createElement('script');
                s.setAttribute('defer', true);
                s.setAttribute('src', url);
                s.onload = callback;
                document.head.insertBefore(s, document.head.firstElementChild);
            }
        }

        try {
            // eslint-disable-next-line no-undef
            if (paypal) {
                console.log("YES");
                // eslint-disable-next-line no-undef
                this.createPaypalButton(paypal);
            } else {
                console.log("NO");
            }
        } catch (e) {
            console.log(e);
        }
        //Test Server DV

       /* loadScript('https://www.paypal.com/sdk/js?currency=EUR&client-id=ARm9H8onj7LOw9Q35qC5Br-cPDeL5Ygb2-Q-6Aw32QszhWRQHyFUFj0NNOX2uHFWYr-yvHtHzpysea3d&components=buttons,funding-eligibility', () => {
          let a = this;
          // eslint-disable-next-line no-undef
          console.log(paypal);
          // eslint-disable-next-line no-undef
          a.createPaypalButton(paypal);
        });
      */

        //PVR WEBSHOP DIGIQUEST Live

        loadScript('https://www.paypal.com/sdk/js?currency=EUR&client-id=Ac32sKsruZ61CyCkM5Mw_zj9evD2KcF1vb5NZwePXCohAj_qYqUtGpPAax0TvQuB3L34-CM2m6E-4UAI&components=buttons,funding-eligibility', () => {
            let a = this;
            // eslint-disable-next-line no-undef
            console.log(paypal);
            // eslint-disable-next-line no-undef
            a.createPaypalButton(paypal);
        });


        //PVR WEBSHOP DIGIQUEST TEST
        /*
        loadScript('https://www.paypal.com/sdk/js?currency=EUR&client-id=AVxrVKuAKiilp6clyzWqpYKc3HTMV06uIpKVXeb9QR8ixNHxmDkFHOu62ZUa_6JhYzpT2Gdqv8lXE6KD&components=buttons,funding-eligibility', () => {
          let a = this;
          // eslint-disable-next-line no-undef
          console.log(paypal);
          // eslint-disable-next-line no-undef
          a.createPaypalButton(paypal);
        });*/
        //testServer live
        /*loadScript('https://www.paypal.com/sdk/js?currency=EUR&client-id=AQ1pxSSPomqvpM91Tea2Icg9pZ2GZErTVvyVPT5fw_dr7zwc0moDaNW4rs1x4ctWowE-F42-TB_-Amig&components=buttons,funding-eligibility', () => {
          let a = this;
          // eslint-disable-next-line no-undef
          console.log(paypal);
          // eslint-disable-next-line no-undef
          a.createPaypalButton(paypal);
        });*/
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu&display=swap');

.ubuntu {
    font-family: 'Ubuntu', sans-serif !important;
}

.sansSerif {
    font-family: 'Open Sans', sans-serif !important;
}

.breadcrumb-item a {
    color: #0000FF;
}
</style>
